$blue: #222034;
$border: #ececec;

body {
  display: flex;
  min-height: 100vh;
  background: #f2f4f8;
  font-family: 'Nunito-Sans', sans-serif; }

h1, h2, h3, h4 {
  font-weight: 500!important; }


#root {
  flex: 1;
  display: flex;

  #auth {
    flex: 1;
    display: flex;
    .logo {
      width: 100%;
      background-image: url('/images/logo-black.png');
      background-size: 70px;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0;
      border-right: 1px solid $border;
      border-bottom: 1px solid $border;
      height: 64px; }

    aside {
      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        .ant-menu {
          border-right: 1px solid $border;
          flex: 1; } } }

    section {
      &#dashboard {
        .mix {
          display: flex;
          flex-direction: column;
          & > div {
            background: white; } } }

      &#profile {
        .banner {
          background-image: url('/images/add-user-modal.jpg');
          background-position: center;
          background-size: cover;
          height: 300px;
          position: relative;
          margin: -24px -24px 5px -24px; }
        .info {
          display: flex;
          flex-direction: column;
          .avatar {
            display: flex;
            justify-content: center;
            margin-top: -40px;
            margin-bottom: 10px;
            img {
              width: 100px;
              height: 100px;
              margin: 0 auto;
              background: white;
              border: 1px solid #eaeaea; } }
          h1, h2, h3, h4, span {
            margin-bottom: 0;
            text-align: center; } } }


      &#users {
        .table-header {
          .ant-card-body {
            display: flex;
            input {
              flex: 1; }
            & > * {
              margin: 0 2px; } } } } }

    footer {
      text-align: center;
      background: white;
      border-top: 1px solid #ececec;
      .social {
        a {
          padding: 2px; } } }

    header {
      border-bottom: 1px solid $border;
      background: '#fff';
      padding: 0;
      display: flex;
      .header-left {
        flex: 1;
        display: flex;
        input {
          flex: 1;
          border: 0;
          outline: 0;
          background-image: url('/images/search.svg');
          background-size: 20px;
          background-repeat: no-repeat;
          padding-left: 60px;
          background-position: 20px center;
          display: flex; } }

      .header-right {
        display: flex;
        justify-content: flex-end;
        & > div {
          text-align: center;
          cursor: pointer;
          min-width: 50px;
          padding: 0 10px;
          border-left: 1px solid $border; } } } }

  #splash {
    flex: 1;
    align-self: center;
    display: flex;
    justify-content: center; }

  #not-auth {
    flex: 1;
    align-self: center;
    .login-container {
      display: flex;
      align-items: stretch;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      box-shadow: 0 10px 40px -20px rgba(0,0,50,.2), 0 10px 80px -20px rgba(0,0,50,.1);
      .rok-logo {
        background-color: $blue;
        height: 200px;
        width: 200px;
        margin-right: 20px;
        background-image: url('/images/logo-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        padding: 100px 30px;
        box-sizing: content-box;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; }
      .login-form-container {
        padding: 40px;
        .login-form {
          width: 300px; } } } } }

.ant-drawer-body {
  padding: 0!important;
  display: flex;
  flex-direction: column;
  .drawer-content {
    padding: 24px;
    overflow-y: auto;
    flex: 1; } }

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left; }

// overrides
.ant-layout {
  min-height: auto!important; }

.ant-card-body {
  padding: 10px 20px;
  font-size: .8rem; }

.ant-menu-item {
  margin: 0!important; }

.table-header {
  .ant-card-body {
    padding: 0;
    margin-bottom: 10px; }
  input {
    width: 200px; } }

.add-user .ant-modal {
  .ant-modal-content {
    background-image: url('/images/add-user-modal.jpg');
    background-size: cover;
    background-position: center bottom;
    .ant-modal-body {
      padding: 200px 10px 20px;
      width: 50%;
      margin: 0 auto; } } }

.avatar-wrapper {
  position: relative;
  height: 100px;
  margin-bottom: 10px;
  img {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px; }
  .add-avatar {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: .3s all;
    opacity: .5;
    border: 1px solid #eaeaea;
    &.uploaded {
      opacity: 0; }
    &:hover {
      opacity: 1;
      background: rgba(0,0,0,0.3); }
    & > div {
      height: 0; }
    input {
      position: absolute;
      width: 100%;
      font-size: 0;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      right: 0;
      cursor: pointer;
      opacity: 0; } } }

.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  .ant-drawer-body {
    flex: 1; } }

.description-group {
  font-size: 20px;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px; }

.anticon.anticon-bars {
  color: black; }
